<template>
  <div id="consentEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="consentData">
              <div class="vx-col w-full">
                <h1>
                  <span v-if="consentData && consentData.id">Uredi tip soglasje</span>
                  <span v-if="!consentData.id">Dodaj tip soglasja</span>
                </h1>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Podatki o soglasju</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">


                  <div class="flex">
                    <div class="w-full">
                      <label class="vs-input--label">Ime <small>(v obliki ključa - brez šumnikov, presledkov in posebnih
                        znakov)</small></label>
                      <vs-input class="w-full" label="" v-model="consentData.consent_name"/>
                    </div>
                  </div>


                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Podajatelj soglasja</label>
                      <ul class="mt-2">
                        <li>
                          <vs-radio v-model="consentData.consent_giver_type" vs-value="CUSTOMER">uporabnik storitve
                          </vs-radio>
                        </li>
                        <li class="mt-2">
                          <vs-radio v-model="consentData.consent_giver_type" vs-value="VOLUNTEER">prostovoljec
                          </vs-radio>
                        </li>
                        <li class="mt-2">
                          <vs-radio v-model="consentData.consent_giver_type" vs-value="COMPANY">podjetje</vs-radio>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Nujnost strinjanja s soglasjem <small>(podajatelj soglasja se mora
                        nujno strinjati s soglasjem, da lahko sodeluje z Zavodom Sopotniki)</small></label>
                      <vs-switch color="success" v-model="consentData.required"
                                 id="evaluation_in_progress">
                        <span slot="on">DA</span>
                        <span slot="off">NE</span>
                      </vs-switch>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Besedilo soglasja</label>
                      <vs-textarea class="w-full" rows="6" v-model="consentData.description"/>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Veljavnost podanega soglasja</label>
                      <ul class="mt-2">
                        <li>
                          <vs-radio v-model="consentData.validity_type" vs-value="UNTIL_REVOKED">neomejeno trajanje
                          </vs-radio>
                        </li>
                        <li class="mt-2">
                          <vs-radio v-model="consentData.validity_type" vs-value="LIMITED">omejeno trajanje</vs-radio>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5" v-if="consentData.validity_type === 'LIMITED'">
                    <div class="w-full">
                      <vs-input class="w-full" label="Število let veljavnosti" v-model="consentData.validity_years"
                                type="number"/>
                    </div>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Način izdaje soglasja</label>
                      <ul class="mt-2">
                        <li>
                          <vs-checkbox v-model="consentData.confirmation_options" vs-value="in_person">ustna potrditev
                          </vs-checkbox>
                        </li>
                        <li class="mt-2">
                          <vs-checkbox v-model="consentData.confirmation_options" vs-value="phone">telefonska
                            potrditev
                          </vs-checkbox>
                        </li>
                        <li class="mt-2">
                          <vs-checkbox v-model="consentData.confirmation_options" vs-value="signature">potrditev s
                            podpisom
                          </vs-checkbox>
                        </li>
                        <li class="mt-2">
                          <vs-checkbox v-model="consentData.confirmation_options" vs-value="e_signature">elektronska
                            potrditev
                          </vs-checkbox>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3"></div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="danger" icon="delete">Izbriši</vs-button>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="success" icon="save" @click="saveConsent">Shrani</vs-button>
            </vs-col>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
  import vSelect from "vue-select";
  import Fuse from 'fuse.js'
  import Datepicker from 'vuejs-datepicker';
  import {slSI} from 'vuejs-datepicker/dist/locale';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';

  let directionsService = null;
  let directionsDisplay = null;
  let geoCoder = null;

  export default {
    name: 'consent-edit',

    components: {
      DirectionsRenderer,
      'v-select': vSelect,
      'datepicker': Datepicker,
      'flat-pickr': flatPickr,
    },
    props: {
      companyId: Number,
      consentId: Number
    },
    data() {
      return {

        consentData: {
          id: this.consentId ? this.consentId : null,
          consent_name: null,
          consent_giver_type: null,
          description: null,
          validity_type: 'UNTIL_REVOKED',
          validity_years: null,
          confirmation_options: []
        }
      }
    },

    computed: {},

    mounted() {
      const _this = this;

      console.log("collapse", _this.$refs.collapsible);

      if (_this.consentId) {
        _this.$vs.loading();
        _this.getConsent();

      } else {

      }
    },

    watch: {
      companyId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");

          if (val) {
            this.getCompany();
          }
        }
      }
    },

    methods: {

      async getConsent() {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'consents/' + _this.consentId)
          .then((res) => {

            _this.consentData = _.filter(res.data.data, {id: _this.consentId})[0];

            console.log("consentData: ", _this.consentData);
            _this.$vs.loading.close();

            _this.showConsentMarker();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      customDateFormatter(date) {
        return this.moment(date).format('DD. MM. YYYY');
      },

      saveConsent() {
        const _this = this;

        let consent = _.omit(_this.consentData, ['disabled', 'deleted', 'created_at', 'updated_at']);
        consent.confirmation_options = JSON.stringify(_this.consentData.confirmation_options);

        if(consent.id === null) {
          delete consent.id;
        }

        _this.$vs.loading();

        console.log("consent data to save", consent);

        Vue.prototype.$http.post(Vue.prototype.$config.api.productionNew + 'consent-management', consent)
          .then((res) => {

            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: 'UpravljanjeSoglasij'});

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      }

    }
  }
</script>

