<template>
  <div id="consentManagementContainer">
    <ConsentEdit :consentId="consentId"></ConsentEdit>
  </div>
</template>

<script>
  import Vue from 'vue';
  import ConsentEdit from "../../components/Sopotniki/consentManagement/ConsentEdit";

  export default {
    name: 'consent-management-edit',

    components: {
      ConsentEdit
    },

    data() {
      return {
        consentId: this.$route.params.consentId ? parseInt(this.$route.params.consentId) : null,
      }
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.consentId = null;
      setTimeout(() => {next()}, 100);
    },

    computed: {},

    mounted() {
      const _this = this;
    },

    methods: {}
  }
</script>

